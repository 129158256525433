import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import MainRouter from './MainRouter'
import LoadingShroud from './LoadingShroud';
import LoginForm from './LoginForm';
import { CheckAuth } from '../../api/AuthAPI';

import '../../css/style.css';
import '@fortawesome/fontawesome-free/css/all.css';

const NextApp = () => {
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    let history = useHistory();

    useEffect(() => {
        checkAuth();
        if (authenticated) {
            history.push(`/CancellationList`)
        }
    }, [authenticated]);
    const checkAuth = () => {
        setLoading(true);
        CheckAuth()
            .then((result) => {
                setAuthenticated(true);
                setLoading(false);
            })
            .catch(() => {
                localStorage.setItem('fchpToken', null);
                sessionStorage.setItem('fchpToken', null);
                setAuthenticated(false);
                setLoading(false);

                history.push(`/`)
            });
    };
    return (
        <div id="app-container">
            {loading ? null : authenticated ?
                (
                    <MainRouter />
                ) : (
                    <div className="">
                        <div className="">
                            <LoginForm checkAuth={checkAuth} />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default NextApp;