import React from 'react';

export default props => {
    return (
        <div className="container-fluid no-print app-header">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 my-auto">
                    <img className="img-fluid header-image" src="../images/logo_cluster.png" alt="First Coast Home Pros" /></div>
                    <div className="col-6 d-none d-xl-block d-lg-block"></div>
                    <div className="col-lg-3 col-md-6 col-sm-12"></div>
                </div>
        </div >
    )
}
