import APIRequest from './APIRequest';

export const Authenticate = (user, password) => {
    return APIRequest({
        url: `api/Authentication/Authenticate`,
        method: "post",
        body: {
            user: user,
            password: password
        }
    });
}

export const CheckAuth = () => {
    return APIRequest({
        url: `api/Authentication/CheckAuth`,
        method: "get",
    });
}
