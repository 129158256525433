const APIRequest = <T>(params: any) => {
    return new Promise((resolve, reject) => {
        let fchpToken = localStorage.getItem('fchpToken');
        if (fchpToken === null)
            fchpToken = sessionStorage.getItem('fchpToken');

        let headers: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        if (fchpToken !== null) {
            headers = {
                ...headers,
                Authorization: `Bearer ${fchpToken}`,
            };
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body),
        })
            .then((response: any) => {
                if (response.status === 401) {
                    reject({ reason: 'Unauthorized', details: response });
                }
                if (!response.ok) {
                    throw Error(response);
                }
                return response.json();
            })
            .then((data: T) => {
                resolve(data);
            })
            .catch((error) => {
                reject({ reason: 'Unknown', details: error });
            });
    });
};

export default APIRequest;
