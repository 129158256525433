import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './Header'

import CancellationList from '../Jobs/CancellationList'

const MainRouter = (props) => {
    return (
        <div className="app-container">
            <Header />
            <Switch>
                <Route
                    exact
                    path="/CancellationList"
                    render={(routeProps) => (
                        <CancellationList />
                    )}
                />
            </Switch>
        </div>
    );
};
export default MainRouter;
