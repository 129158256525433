import React, { useState, useEffect, } from 'react';
import { ListGroup, Accordion } from 'react-bootstrap';
import { GetCancellationJobs } from '../../api/JobsAPI';
import CancellationAccordion from './CancellationAccordion';


const CancellationList = (props) => {
    const [jobs, setJobs] = useState([]);
    const [timer, setTimer] = useState([]);
    const TIMER_INTERVAL = 2500;

    useEffect(() => {
        document.title = "Cancellation List"
        setTimer(setTimeout(() => { loadJobs(); }, TIMER_INTERVAL));
    }, []);


    const loadJobs = () => {
        GetCancellationJobs()
            .then(res => {
                clearTimeout(timer);
                setTimer(setTimeout(() => { loadJobs(); }, TIMER_INTERVAL));
                setJobs(res.jobs)
            })
    }




    return (
        <div className='app-main container-fluid'>
            { (jobs !== null && jobs.length !== 0 && Object.keys(jobs).length !== 0) ?
                <>
                {Object.keys(jobs).map((eventType, index) =>
                    <ListGroup className="my-3">
                        <ListGroup.Item className="fchp-data-list">{eventType}</ListGroup.Item>
                        <ListGroup.Item className="fchp-data-list">
                            <div className="row">
                                <div className="col-md-1">
                                    Job Number
                            </div>
                                <div className="col-md-2">
                                    Client Name
                            </div>
                                <div className="col-md-1">
                                    Zip Code
                            </div>
                                <div className="col-md-3">
                                    Appointment Type
                            </div>
                                <div className="col-md-1">
                                    Man Hours
                            </div>
                                <div className="col-md-2">
                                    Currently Scheduled
                            </div>
                                <div className="col-md-2">
                                    Notes
                            </div>
                            </div>
                        </ListGroup.Item>
                        {Object.keys(jobs[eventType]).length === 0 && <ListGroup.Item>No Jobs To Display</ListGroup.Item>}
                        {Object.keys(jobs[eventType]).length > 0 && Object.keys(jobs[eventType]).map((schedule) => {
                            return <>
                                {Object.keys(jobs[eventType][schedule]).length > 0 && Object.keys(jobs[eventType][schedule]).length === 1 &&
                                    <ListGroup.Item key={`LGI-${jobs[eventType][schedule][0].jobNumber}`} className={ "hover-list" }>
                                        <div className="row">
                                            <div className="col-md-1">{jobs[eventType][schedule][0].jobNumber}</div>
                                            <div className="col-md-2">{jobs[eventType][schedule][0].cName}</div>
                                            <div className="col-md-1">{jobs[eventType][schedule][0].zipcode}</div>
                                            <div className="col-md-3">{jobs[eventType][schedule][0].eventType}</div>
                                            <div className="col-md-1">{jobs[eventType][schedule][0].duration}</div>
                                            <div className="col-md-2">{jobs[eventType][schedule][0].startDate}</div>
                                            <div className="col-md-2">{jobs[eventType][schedule][0].extra2}</div>
                                        </div>
                                    </ListGroup.Item>
                                }
                                {Object.keys(jobs[eventType][schedule]).length > 0 && Object.keys(jobs[eventType][schedule]).length > 1 &&
                                    <CancellationAccordion scheduleID={schedule} scheduleJobs={jobs[eventType][schedule]} />
                                }
                            </>
                        }
                        )}
                    </ListGroup>
                    )}
                </>
                : <h3 className='text-center m-5'>No records yet... Searching...</h3>
            }
            </div>
    );
};
export default CancellationList;
