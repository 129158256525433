import React, { useState, useEffect, } from 'react';
import { ListGroup, Accordion } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';


function ContextAwareToggle({ headingJob, children, eventKey, callback }) {
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );
    return (
        <ListGroup.Item key={`LGI-${headingJob.jobNumber}`} className={"click-list"}
                //onClick={(schedule) => useAccordionButton(props.scheduleID, () => { console.log("stuff"); })}
            onClick={decoratedOnClick}
            >
                <div className="row">
                    <div className="col-md-1">{headingJob.jobNumber}</div>
                    <div className="col-md-2">{headingJob.cName}</div>
                    <div className="col-md-1">{headingJob.zipcode}</div>
                    <div className="col-md-3">{headingJob.eventType}</div>
                    <div className="col-md-1">{headingJob.duration}</div>
                    <div className="col-md-2">{headingJob.startDate}</div>
                    <div className="col-md-2">{headingJob.extra2}</div>
                </div>
        </ListGroup.Item>
    );
}
const CancellationAccordion = (props) => {
    return (

        <Accordion className="grouped-accordion" defaultActiveKey="0">
            <ContextAwareToggle headingJob={props.scheduleJobs[0]} eventKey={props.scheduleID} />
            {props.scheduleJobs.slice(1).map((subJob, ji) =>
                <Accordion.Collapse eventKey={props.scheduleID}>
                    <ListGroup.Item key={`LGI-${subJob.jobNumber}`} className={"hover-list click-list-subitem"}>
                        <div className="row">
                            <div className="col-md-1">{subJob.jobNumber}</div>
                            <div className="col-md-2">{subJob.cName}</div>
                            <div className="col-md-1">{subJob.zipcode}</div>
                            <div className="col-md-3">{subJob.eventType}</div>
                            <div className="col-md-1">{subJob.duration}</div>
                            <div className="col-md-2">{subJob.startDate}</div>
                            <div className="col-md-2">{subJob.extra2}</div>
                        </div>
                    </ListGroup.Item>
                </Accordion.Collapse>
            )}
        </Accordion>

    );
};
export default CancellationAccordion;
