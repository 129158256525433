import './css/custom.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import NextApp from './components/App/app.js';
import { BrowserRouter } from 'react-router-dom';

var rootElement = document.getElementById('root');

ReactDOM.render(
      <BrowserRouter>
          <NextApp />
    </BrowserRouter>, rootElement
);
