import React, { useState, useEffect } from 'react';
import LoadingShroud from './LoadingShroud';
import { useHistory } from 'react-router-dom';

import { Authenticate } from '../../api/AuthAPI';

const LoginForm = props => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    let history = useHistory();

    useEffect(() => {
        document.title = "FCHP - Login"
    }, []);

    const login = () => {
        setLoading(true);
        Authenticate(username, password).then(result => {
            if (result.success && result.token != null) {
                //localStorage.removeItem("fchpToken");
                localStorage.setItem("fchpToken", result.token);
                sessionStorage.setItem("fchpToken", result.token);

                setLoading(false);
                props.checkAuth();
                history.push(`/CancellationList`)
            } else {
                var message = "Invalid username or password."
                setMessage(message);
                setLoading(false);
            }
        }).catch(info => {
            console.error(info);
        })
    }
    return (
        <div className="form-login">
            {loading ? (
                <div className="app-loading">
                    <LoadingShroud Active={loading}/>
                </div>
            ) : (
                    <div className="app-container-login">
                        <div className="container">
                            <div className="row justify-content-center h-100">
                                <div className="col-xl-6 col-sm-12 m-auto white-bg p1-border px-5 py-3">
                                    <div className="row justify-content-center">
                                        <div className="col-10 row justify-content-center">
                                            <img className="img-fluid" src="../images/logo_cluster_base.png" alt="First Coast Home Pros" style={{ "width": "340px", "height": "340px"}} />
                                        </div>
                                    </div>
                                    <form className="" method="post" onSubmit={(e) => { e.preventDefault(); login() }}>
                                        <div className="row mt-3">
                                            <div className="col text-center">
                                                <input type="text" className="fchp-input-100" name="Username" id="Username" placeholder="Username" value={username}
                                                    onChange={e => setUsername(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col text-center">
                                                <input type="password" className="fchp-input-100" name="Password" id="Password" placeholder="Password" value={password}
                                                    onChange={e => setPassword(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <span style={{ color: 'red' }}>{message}</span>
                                            </div>
                                            <div className="col text-right">
                                                <input type="submit" value="Login" className="fchp-button" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default LoginForm;